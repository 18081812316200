<template>
  <img
    :src="hamburgerMenu"
    class="cursor-pointer md:hidden relative col-start-1 col-end-2"
    @click="hamburgerMenuClick"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'HamburgerMenu',
  props: ['toggleHamburgerMenu'],
  computed: {
    ...mapState(['language', 'profileOpen']),
  },
  data() {
    return {
      hamburgerMenu: require('../assets/styles/icons/hamburger-menu.svg'),
    };
  },
  methods: {
    hamburgerMenuClick() {
      if (!this.profileOpen) {
        this.openMenu();
        this.setModalOpen(true);
      }
    },
    ...mapActions(['openMenu', 'setModalOpen']),
  },
};
</script>
