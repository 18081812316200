<template>
  <div class="mx-auto p-4">
    <button v-for="obj in surveyResponses" :key="obj?.id">
      <router-link
        :to="{ name: 'SurveyResponse', params: { surveyResponse: obj.id } }"
      >
        {{ obj?.surveyTaker?.name }}
      </router-link>
    </button>
  </div>
  <div>
    <router-link to="/surveyTaker">/surveyTaker</router-link>
  </div>
</template>

<script>
import { useQuery, useResult } from '@vue/apollo-composable';
import SurveyResponsesQuery from '@/graphql/SurveyResponses.query.gql';
import { logErrorMessages } from '@vue/apollo-util';

export default {
  setup() {
    const { result, onError } = useQuery(SurveyResponsesQuery, null, {
      fetchPolicy: 'cache-and-network',
    });
    const surveyResponses = useResult(
      result,
      null,
      (data) => data.SurveyResponse
    );

    onError((err) => {
      logErrorMessages(err);
    });

    return { surveyResponses };
  },
};
</script>
