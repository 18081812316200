<template>
  <div class="pr-2">
    <img :src="checkmark" />
  </div>
</template>

<script>
export default {
  name: 'Checkmark',
  data() {
    return {
      checkmark: require('../assets/styles/icons/checkmark.svg'),
    };
  },
};
</script>
