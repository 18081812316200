<template>
  <input
    :type="questionType === 'INPUT_NUMBER' ? 'number' : 'text'"
    :required="required"
    v-model="change"
    :min="min"
    :max="max"
    :class="questionType === 'INPUT_NUMBER' ? 'inp' : 'inp col-span-full'"
    @focus="stain"
    @blur="m"
  />
</template>

<script>
import useGetAnswersQuery from '@/composables/useGetAnswersQuery';
import useAnswerQuestionMutation from '@/composables/useAnswerQuestionMutation';
export default {
  name: 'QuestionInput',
  props: {
    prompt: {
      type: String,
      required: true,
    },
    questionKey: {
      type: String,
      required: true,
    },
    required: Boolean,
    min: Number,
    max: Number,
    surveyResponse: {
      type: String,
      required: true,
    },
    questionType: {
      type: String,
      required: true,
      validator: function (str) {
        switch (str) {
          case 'INPUT_NUMBER':
          case 'INPUT_TEXT':
            return true;
        }
        return false;
      },
    },
  },
  data() {
    return {
      dirty: false,
      v: '',
    };
  },
  methods: {
    stain() {
      this.dirty = true;
    },
    m() {
      return this.answerQuestion({
        surveyResponse: this.surveyResponse,
        value: this.v === '' ? null : this.v,
        question: this.questionKey,
      });
    },
    s(value) {
      this.v = value;
    },
  },
  computed: {
    change: {
      get() {
        if (!this.dirty)
          this.s(this.dataPoints?.[0]?.answers?.[0]?.ANSWERS_rel?.[0]?.value);
        return this.dirty
          ? this.v
          : this.dataPoints?.[0]?.answers?.[0]?.ANSWERS_rel?.[0]?.value;
      },
      set(answer) {
        this.s(answer);
      },
    },
  },
  setup(props) {
    const { dataPoints } = useGetAnswersQuery(
      props.surveyResponse,
      props.questionKey
    );

    const { answerQuestion } = useAnswerQuestionMutation();

    return { answerQuestion, dataPoints };
  },
};
</script>
