import SectionCompleteQuery from '@/graphql/SectionComplete.query.gql';
import { useQuery, useResult } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

export default function SectionComplete(surveyResponse, section) {
  const { result, onError, loading } = useQuery(
    SectionCompleteQuery,
    {
      surveyResponse,
      section,
    },
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const sectionComplete = useResult(
    result,
    null,
    (data) => data.SectionComplete
  );

  onError((err) => {
    logErrorMessages(err);
  });

  return {
    sectionComplete,
    loading,
  };
}
