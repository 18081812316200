<template>
  <button @click="executeOnClick" class="btn filled-btn w-full btn-continue">
    Continue
  </button>
</template>

<script>
export default {
  name: 'ContinueButton',
  props: {
    executeOnClick: {
      type: Function,
      required: true,
    },
  },
};
</script>
