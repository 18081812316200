import MergeSurveyTakerMutation from '@/graphql/MergeSurveyTaker.mutation.gql';
import { useMutation } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

export default function useMergeSurveyTakerMutation() {
  const { mutate: mergeSurveyTaker, onError } = useMutation(
    MergeSurveyTakerMutation,
    () => ({
      fetchPolicy: 'no-cache',
      refetchQueries: () => ['SurveyTaker'],
    })
  );

  onError((err) => {
    logErrorMessages(err);
  });

  return {
    mergeSurveyTaker,
  };
}
