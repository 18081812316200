<template>
  <div
    class="flex flex-row justify-between items-center w-full relative z-10"
    id="pageHeader"
    :class="[
      slideMenuUp && !isModalOpen ? 'up' : ' ',
      slideMenuDown && !isModalOpen ? 'down' : ' ',
      'bg-white',
    ]"
  >
    <div
      class="grid grid-cols-2 gap-3 p-2 justify-center items-center auto-cols-auto"
    >
      <div
        class="backButton-s"
        @click="BackButtonClickAction"
        :class="isBackButtonSlotFilled ? 'block' : 'hidden'"
      >
        <slot name="backButton"></slot>
      </div>
      <div
        class="hamburgerMenu-s md:hidden"
        @click="hamburgerClickAction"
        :class="hideHmBasedOnRoute ? 'hidden' : 'block'"
      >
        <slot name="hamburgerMenu"><HamburgerMenu /></slot>
      </div>
      <div class="languageSelector-s" @click="languageSelectorAction">
        <slot name="languageSelector"></slot>
      </div>
    </div>
    <div :class="isParAvatarSlotFilled ? 'block' : 'hidden'">
      <slot name="parAvatar"></slot>
    </div>
    <div class="flex flex-row justify-between p-2.5" @click="exitButtonAction">
      <slot name="parProfile"> </slot>
    </div>
  </div>
</template>

<script>
import HamburgerMenu from '@/components/HamburgerMenu';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { REACT_APP_BASE_URL } from '@/constants';
import { mapState } from 'vuex';

export default {
  name: 'HeaderComponent',
  components: {
    HamburgerMenu,
  },
  props: {
    hamburgerClickAction: Function,
    languageSelectorAction: Function,
    exitButtonAction: Function,
  },
  computed: {
    ...mapState(['isModalOpen']),
  },
  setup(_, { slots }) {
    let isBackButtonSlotFilled = ref(null);
    let isParAvatarSlotFilled = ref(null);
    let slideMenuUp = ref(null);
    let slideMenuDown = ref(null);
    const router = useRouter();
    const cancelUrl = `${REACT_APP_BASE_URL}/participants`;
    let lastScrollTop = 0;
    const routesToHideHamburgerMenu = [
      'temp',
      'SurveyTaker',
      'BasicInformation',
      'Home',
    ];
    isBackButtonSlotFilled.value = !!slots?.backButton;
    isParAvatarSlotFilled.value = !!slots?.parAvatar;
    const hideHmBasedOnRoute = computed(() =>
      routesToHideHamburgerMenu.includes(router.currentRoute?.value?.name)
    );
    const cancelText = computed(() =>
      router?.currentRoute?.value?.query?.mode === 'UPDATE' ||
      router?.currentRoute?.value?.name === 'SurveyResponse'
        ? 'Save & Close'
        : 'Cancel'
    );
    window.addEventListener(
      'scroll',
      function () {
        let st = window.pageYOffset;
        if (st > lastScrollTop) {
          // downscroll code
          slideMenuUp.value = true;
          slideMenuDown.value = false;
        } else {
          // upscroll code
          slideMenuDown.value = true;
          slideMenuUp.value = false;
        }
        if (st === 0) {
          slideMenuDown.value = false;
          slideMenuUp.value = false;
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      },
      false
    );

    return {
      cancelText,
      cancelUrl,
      isBackButtonSlotFilled,
      isParAvatarSlotFilled,
      slideMenuUp,
      slideMenuDown,
      hideHmBasedOnRoute,
    };
  },
};
</script>
