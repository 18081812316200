<template>
  <div class="text-center w-full mt-6 col-span-full">
    <button class="text-btn" @click="buttonAction">
      {{ label }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'SaveAndClose',
  props: {
    buttonAction: {
      type: Function,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
