import SurveyResponseCompleteQuery from '@/graphql/SurveyResponseComplete.query.gql';
import { useQuery, useResult } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

export default function SurveyResponseComplete(surveyResponse) {
  const { result, onError, loading } = useQuery(
    SurveyResponseCompleteQuery,
    {
      surveyResponse,
    },
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const surveyResponseComplete = useResult(
    result,
    null,
    (data) => data.SurveyResponseComplete
  );

  onError((err) => {
    logErrorMessages(err);
  });

  return {
    surveyResponseComplete,
    loading,
  };
}
