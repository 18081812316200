import AnswerQuestionMutation from '@/graphql/AnswerQuestion.mutation.gql';
import { useMutation } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

export default function useAnswerQuestionMutation() {
  const { mutate: answerQuestion, onError, loading } = useMutation(
    AnswerQuestionMutation,
    () => ({
      fetchPolicy: 'no-cache',
      refetchQueries: () => [
        'GetAnswers',
        'RenderQuestion',
        'SurveyResponseComplete',
        'SectionComplete',
        'RequiredQuestionUnanswered',
        'FirstRequiredButUnansweredQuestionInSectionQuery',
      ],
    })
  );

  onError((err) => {
    logErrorMessages(err);
  });

  return {
    answerQuestion,
    loading,
  };
}
