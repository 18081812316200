<template>
  <HeaderComponent>
    <template #backButton>
      <div class="flex flex-row justify-center items-center">
        <button
          class="flex flex-row justify-center items-center text-primary"
          @click="goToParticipantDashboard"
        >
          <span class="text-2rem">&lt;</span>
          <span class="text-smBase pl-1 font-bold">Back</span>
        </button>
      </div>
    </template>
  </HeaderComponent>
  <section class="flex flex-col justify-center items-center px-1 pt-24">
    <h2 class="text-2xl md:text-4xl mb-6 text-text font-bold">
      Enter HMIS ID to get started
    </h2>
    <form
      @submit.prevent="next"
      class="flex flex-col justify-between sm:justify-start items-center content-between mb-14 w-full p-2.5 xs:max-w-xs"
    >
      <input
        type="search"
        name="hmisid"
        required
        id="entr-survey-taker-hmisid"
        v-model.trim="hmisid"
        class="inp hmis-inp"
        placeholder="Enter HMIS"
      />
      <input
        type="submit"
        value="Continue"
        class="filled-btn mt-96 md:mt-8 md:w-56"
        :disabled="loading"
        :class="hmisid === hmisidFromCard && 'hidden'"
      />
    </form>
  </section>
  <div v-if="hmisid" class="sm:container mx-4 sm:mx-auto text-center">
    <section
      v-if="hmisid === hmisidFromCard && isSurveyTaker && !isParticipant"
    >
      <h3 class="text-xl font-bold">
        We found the following incomplete profile with this HMIS ID
      </h3>
      <p class="mb-8 mt-4">Please complete participant's profile below</p>
      <SurveyTakerCard
        :hmisid="hmisid"
        :name="name"
        :id="id"
        :status="status"
        mode="UPDATE"
      />
    </section>
    <section v-if="hmisid === hmisidFromCard && isParticipant">
      <h3 class="text-xl font-bold">
        We found the following profile with this HMIS ID
      </h3>
      <p class="mb-8 mt-4">View profile to make any changes</p>
      <SurveyTakerCard
        :hmisid="hmisid"
        :name="name"
        :id="id"
        :status="status"
        mode="VIEW"
      />
    </section>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useResult } from '@vue/apollo-composable';
import usePARQuery from '@/composables/usePARQuery';
import { goToParticipantDashboard } from '@/composables/useSaveAndFn';
import SurveyTakerCard from '@/components/SurveyTakerCard';
import HeaderComponent from '@/components/HeaderComponent';
import router from '@/router';
export default {
  name: 'SurveyTaker',
  components: { SurveyTakerCard, HeaderComponent },
  setup() {
    const hmisid = ref('');
    const hmisidFromCard = ref('');
    const name = ref('');
    const id = ref('');
    const status = ref('');
    const isParticipant = ref(null);
    const isSurveyTaker = ref(null);

    const { parQuery, loading } = usePARQuery(hmisid);
    // NOTE: open for a better name
    const next = () => {
      const PAR = useResult(parQuery, null, (data) => data.PAR?.[0]);

      if (!PAR.value) {
        // if no data, push to new route to create SurveyTaker with entered hmisid
        return router.push({
          name: 'BasicInformation',
          params: {
            hmisid: hmisid.value,
          },
        });
      } else {
        name.value = PAR.value.name;
        hmisidFromCard.value = PAR?.value?.HMISID;
        id.value = PAR.value.id;
        status.value = PAR.value?.status?.type;
      }

      isParticipant.value = PAR.value?.labels?.includes('Participant');
      isSurveyTaker.value = PAR.value?.labels?.includes('SurveyTaker');
    };

    return {
      hmisid,
      hmisidFromCard,
      id,
      name,
      status,
      loading,
      next,
      isParticipant,
      isSurveyTaker,
      goToParticipantDashboard,
    };
  },
};
</script>
