<template>
  <button
    v-for="option in answerChoices"
    :key="option.id"
    :class="getClass(option.id)"
    @click="selectOption(option.id)"
  >
    <Checkmark v-if="checkCheck(option.id)" />
    <Markdown :source="option['text_' + language]" />
  </button>
</template>

<script>
import Markdown from 'vue3-markdown-it';
import useGetAnswersQuery from '@/composables/useGetAnswersQuery';
import useAnswerQuestionMutation from '@/composables/useAnswerQuestionMutation';
import Checkmark from '@/components/Checkmark';
import { mapState } from 'vuex';
export default {
  name: 'QuestionChoice',
  // using data here to showcase that this whole damn thing shouldn't be here
  data() {
    return {
      // while set to false, we gotta rely on real data from graphql
      iKnowWhatsUp: false,
      // this abomination keeps track internally which id was selected manually
      clickidyTapped: [],
    };
  },
  props: {
    answerChoices: {
      type: Array,
      required: true,
      validator: function (arr) {
        return arr.every(function (obj) {
          // ensures that the objects of the array all have an id (and a text for the selected language)
          return obj.id /*&& obj['text_' + this.language]*/;
        });
      },
    },
    questionKey: {
      type: String,
      required: true,
    },
    prompt: {
      type: String,
      required: true,
    },
    surveyResponse: {
      type: String,
      required: true,
    },
    questionType: {
      type: String,
      required: true,
      validator: function (str) {
        switch (str) {
          case 'SINGLE_CHOICE':
          case 'MULTIPLE_CHOICE':
            return true;
        }
        return false;
      },
    },
  },
  components: {
    Checkmark,
    Markdown,
  },
  computed: {
    ...mapState(['language']),
  },
  methods: {
    // helper function for Checkmark rendering
    checkCheck(id) {
      if (this.questionType === 'MULTIPLE_CHOICE') {
        if (this.iKnowWhatsUp) {
          return this.clickidyTapped.includes(id);
        } else {
          return this.answerIds?.includes(id);
        }
      }
      return false;
    },
    selectOption(id) {
      let answers;
      if (this.questionType === 'SINGLE_CHOICE') {
        if (this.iKnowWhatsUp) {
          this.clickidyTapped = this.clickidyTapped.includes(id) ? [] : [id];
        } else {
          this.clickidyTapped = answers = this.answerIds?.includes(id)
            ? []
            : [id];
          this.iKnowWhatsUp = true;
        }
      } else if (this.questionType === 'MULTIPLE_CHOICE') {
        if (this.iKnowWhatsUp) {
          this.clickidyTapped = this.clickidyTapped.includes(id)
            ? [...this.clickidyTapped.filter((el) => el !== id)]
            : [...this.clickidyTapped, id];
        } else {
          // id not found in selection? add it, otherwise remove it
          this.clickidyTapped = answers = this.answerIds.includes(id)
            ? [...this.answerIds.filter((el) => el !== id)]
            : [...this.answerIds, id];
          this.iKnowWhatsUp = true;
        }
      }
      return this.answerQuestion({
        surveyResponse: this.surveyResponse,
        question: this.questionKey,
        answers: this.iKnowWhatsUp ? this.clickidyTapped : answers,
      });
    },
    getClass(id) {
      if (this.iKnowWhatsUp) {
        if (!this.clickidyTapped.includes(id)) return 'btn';
        switch (this.questionType) {
          case 'SINGLE_CHOICE':
            return 'btn-selected';
          case 'MULTIPLE_CHOICE':
            return 'btn-selected-multi';
        }
      } else {
        if (!this.answerIds?.includes(id)) return 'btn';
        switch (this.questionType) {
          case 'SINGLE_CHOICE':
            return 'btn-selected';
          case 'MULTIPLE_CHOICE':
            return 'btn-selected-multi';
        }
      }
    },
  },
  setup(props) {
    const { answerIds } = useGetAnswersQuery(
      props.surveyResponse,
      props.questionKey,
      null
    );

    const { answerQuestion } = useAnswerQuestionMutation(props);

    return {
      answerIds,
      answerQuestion,
    };
  },
};
</script>
