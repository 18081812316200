<template>
  <button @click="goBack" class="btn btn-prev">Back</button>
</template>

<script>
import { mapActions } from 'vuex';
import router from '@/router';
export default {
  name: 'PreviousButton',
  props: {
    hmisid: {
      type: String,
    },
  },
  methods: {
    goBack() {
      if (this?.$route?.name === 'BasicInformation') {
        return router.push('/surveyTaker');
      } else if (this?.$props?.hmisid !== null) {
        return router.push(`/surveyTaker/${this?.$props?.hmisid}?mode=UPDATE`);
      } else {
        return this.previousSection();
      }
    },
    ...mapActions(['previousSection']),
  },
};
</script>
