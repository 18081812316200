<template>
  <button
    v-on="executeOnClick ? { click: executeOnClick } : { click: submitAndBail }"
    :disabled="
      mutationLoading || loading || (!executeOnClick && !surveyResponseComplete)
    "
    class="btn btn-submit"
  >
    Submit
  </button>
  <div v-if="errors">{{ errors }}</div>
</template>

<script>
import useCreateParticipantFromSurveyResponseMutation from '@/composables/useCreateParticipantFromSurveyResponseMutation';
import useSurveyResponseCompleteQuery from '@/composables/useSurveyResponseCompleteQuery';
import { goToParticipantDashboard } from '@/composables/useSaveAndFn';
import { logErrorMessages } from '@vue/apollo-util';
import { ref } from 'vue';
import { mapActions, mapState, useStore } from 'vuex';
export default {
  name: 'SubmitFormButton',
  props: {
    surveyResponse: {
      type: String,
      required: true,
    },
    executeOnClick: {
      type: Function,
      required: false,
    },
  },
  computed: {
    ...mapState(['language', 'shouldCheck', 'showLoadingSpinner']),
  },
  methods: {
    ...mapActions(['nextSection', 'setShouldCheck', 'setShowLoadingSpinner']),
  },
  setup(props) {
    const store = useStore();
    const {
      createParticipant,
      loading: mutationLoading,
      onError,
    } = useCreateParticipantFromSurveyResponseMutation();

    const { surveyResponseComplete, loading } = useSurveyResponseCompleteQuery(
      props.surveyResponse
    );

    let errors = ref('');

    onError((err) => {
      logErrorMessages(err);
      errors.value = err;
    });

    const submitAndBail = async () => {
      if (loading || mutationLoading) {
        store.dispatch('setShowLoadingSpinner', true);
      }
      await createParticipant({ surveyResponse: props.surveyResponse });
      goToParticipantDashboard();
      store.dispatch('setShowLoadingSpinner', false);
    };

    return {
      submitAndBail,
      mutationLoading,
      surveyResponseComplete,
      loading,
      errors,
    };
  },
};
</script>
