<template>
  <div v-if="loading"><p>Loading survey data...</p></div>
  <div v-else class="md:flex h-full">
    <Navigation
      :class="[menuOpen ? 'block' : 'hidden']"
      :sections="
        surveyWithResponse?.survey?.SECTIONS_rel?.flatMap(
          (secRel) => secRel.Section
        ) || []
      "
      :closeNavigation="closeMenu"
      :hmisid="surveyWithResponse?.surveyTaker?.HMISID"
    />
    <div class="w-full">
      <HeaderComponent>
        <template #languageSelector>
          <LanguageSelector />
        </template>
        <template #parAvatar>
          <div
            class="flex flex-row justify-center items-center xs:mb-6 md:mb-0 hidden md:flex pt-2"
          >
            <Avatar
              :name="surveyWithResponse?.surveyTaker?.name"
              size="8"
              avatarFontSize="lg"
            />
            <span class="pl-2 font-bold">{{
              surveyWithResponse?.surveyTaker?.name
            }}</span>
          </div>
        </template>
        <template #parProfile>
          <ParProfile :email="loggedInUser?.email" />
        </template>
      </HeaderComponent>
      <div
        class="mx-auto p-4 max-w-lg relative"
        :class="isModalOpen ? 'xs:pt-14 md:p-4' : ''"
      >
        <div class="flex flex-row justify-center items-center mb-2.5 md:hidden">
          <Avatar
            :name="surveyWithResponse?.surveyTaker?.name"
            size="8"
            avatarFontSize="lg"
          />
          <span class="pl-2 font-bold">{{
            surveyWithResponse?.surveyTaker?.name
          }}</span>
        </div>
        <Section
          :surveyResponse="surveyWithResponse"
          :section="
            surveyWithResponse?.survey?.SECTIONS_rel?.[currentSection]?.Section
              .id
          "
          :currentSection="currentSection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useQuery, useResult } from '@vue/apollo-composable';
import Navigation from '@/components/Navigation.vue';
import SurveyResponseQuery from '@/graphql/SurveyResponse.query.gql';
import { logErrorMessages } from '@vue/apollo-util';
import { mapActions, mapState } from 'vuex';
import Section from '@/components/Section';
import Avatar from '@/components/Avatar';
import HeaderComponent from '@/components/HeaderComponent';
import LanguageSelector from '@/components/LanguageSelector';
import ParProfile from '@/components/ParProfile';

export default {
  name: 'Home',
  props: {
    surveyResponse: {
      type: String,
      required: true,
    },
  },
  components: {
    Avatar,
    HeaderComponent,
    LanguageSelector,
    Navigation,
    Section,
    ParProfile,
  },
  setup(props) {
    const { result, onError, loading } = useQuery(SurveyResponseQuery, props, {
      fetchPolicy: 'cache-and-network',
    });
    const surveyWithResponse = useResult(
      result,
      null,
      (data) => data.SurveyResponse?.[0]
    );
    const loggedInUser = useResult(result, null, (data) => data.me);
    onError((err) => {
      logErrorMessages(err);
    });
    return {
      surveyWithResponse,
      loggedInUser,
      loading,
    };
  },
  computed: {
    ...mapState(['currentSection', 'menuOpen', 'isModalOpen']),
  },
  methods: {
    ...mapActions(['openMenu', 'closeMenu']),
  },
};
</script>
