import { createStore } from 'vuex';

export const initialState = {
  language: 'en',
  currentSection: 0,
  menuOpen: false,
  profileOpen: false,
  appScrolling: true,
  isModalOpen: false,
  shouldCheck: false,
  showLoadingSpinner: false,
};

export const mutations = {
  SET_LANGUAGE(state, language) {
    state.language = language;
  },
  SET_CURRENT_SECTION(state, section) {
    state.currentSection = section;
  },
  SET_MENU_OPEN(state, isOpen) {
    state.menuOpen = isOpen;
  },
  SET_PROFILE_OPEN(state, isProfileOpen) {
    state.profileOpen = isProfileOpen;
  },
  SET_APP_SCROLLING(state, isScrollable) {
    state.appScrolling = isScrollable;
  },
  SET_MODAL_OPEN(state, isModalOpen) {
    state.isModalOpen = isModalOpen;
  },
  SET_SHOULD_CHECK(state, shouldCheck) {
    state.shouldCheck = shouldCheck;
  },
  SET_SHOW_LOADING_SPINNER(state, showLoadingSpinner) {
    state.showLoadingSpinner = showLoadingSpinner;
  },
};

export const actions = {
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language);
  },
  setCurrentSection({ commit }, sectionNumber) {
    commit('SET_CURRENT_SECTION', sectionNumber);
  },
  nextSection({ state, commit }) {
    commit('SET_SHOULD_CHECK', false);
    commit('SET_CURRENT_SECTION', state.currentSection + 1);
  },
  previousSection({ state, commit }) {
    const { currentSection } = state;
    if (currentSection > 0) commit('SET_CURRENT_SECTION', currentSection - 1);
  },
  openMenu({ commit }) {
    commit('SET_MENU_OPEN', true);
  },
  closeMenu({ commit }) {
    commit('SET_MENU_OPEN', false);
  },
  openProfile({ commit }) {
    commit('SET_PROFILE_OPEN', true);
  },
  closeProfile({ commit }) {
    commit('SET_PROFILE_OPEN', false);
  },
  enableAppScrolling({ commit }) {
    commit('SET_APP_SCROLLING', true);
  },
  disableAppScrolling({ commit }) {
    commit('SET_APP_SCROLLING', false);
  },
  setModalOpen({ commit }, isModalOpen) {
    commit('SET_MODAL_OPEN', isModalOpen);
  },
  setShouldCheck({ commit }, shouldCheck) {
    commit('SET_SHOULD_CHECK', shouldCheck);
  },
  setShowLoadingSpinner({ commit }, showLoadingSpinner) {
    commit('SET_SHOW_LOADING_SPINNER', showLoadingSpinner);
  },
};

export default createStore({
  state: initialState,
  mutations,
  actions,
});
