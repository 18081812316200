<template>
  <template v-if="isComplete">
    <div
      v-if="!isLastSection"
      class="-ml-px absolute mt-0.5 top-4 left-1 w-0.5 h-full bg-white"
      aria-hidden="true"
    />
    <span class="relative flex items-center group" aria-current="section">
      <span class="h-9 flex items-center">
        <span
          class="relative z-10 w-2 h-2 flex items-center justify-center bg-white rounded-full transform scale-150"
        >
          <CheckIcon class="text-navBg" aria-hidden="true" />
        </span>
      </span>
      <span class="ml-4 min-w-0 flex flex-col text-white">
        <button
          class="text-base tracking-wide whitespace-nowrap"
          :class="isCurrentSection ? 'font-bold' : ''"
        >
          {{ sectionName }}
        </button>
      </span>
    </span>
  </template>
  <template v-else-if="isCurrentSection || !isComplete">
    <div
      v-if="!isLastSection"
      class="-ml-px absolute mt-0.5 top-4 left-1 w-0.5 h-full bg-gray-300"
      aria-hidden="true"
    />
    <span class="relative flex items-center group" aria-current="section">
      <span class="h-9 flex items-center" aria-hidden="true">
        <span
          class="relative z-10 w-2 h-2 flex items-center justify-center bg-white text-navbg rounded-full"
          :class="isCurrentSection ? 'transform scale-150' : ''"
        >
          <span class="bg-white rounded-full" />
        </span>
      </span>
      <span class="ml-4 min-w-0 flex flex-col text-white">
        <button
          class="text-base tracking-wide whitespace-nowrap"
          :class="isCurrentSection ? 'font-bold' : ''"
        >
          {{ sectionName }}
        </button>
      </span>
    </span>
  </template>
  <template v-else>
    <div
      v-if="!isLastSection"
      class="-ml-px absolute mt-0.5 top-4 left-1 w-0.5 h-full bg-gray-300"
      aria-hidden="true"
    />
    <span class="relative flex items-center group" aria-current="section">
      <span class="h-9 flex items-center" aria-hidden="true">
        <span
          class="relative z-10 w-2 h-2 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400"
        >
          <span class="bg-transparent rounded-full group-hover:bg-gray-300" />
        </span>
      </span>
      <span class="ml-4 min-w-0 flex flex-col text-white">
        <button
          class="text-base text-gray-500 tracking-wide whitespace-nowrap"
          :class="sidx === currentSection ? 'font-bold' : ''"
        >
          {{ sectionName }}
        </button>
      </span>
    </span>
  </template>
</template>

<script>
// TODO: refactor template
import SectionComplete from '@/composables/useSectionCompleteQuery';
import { CheckIcon } from '@heroicons/vue/solid';
export default {
  name: 'NavigationListItem',
  components: {
    CheckIcon,
  },
  props: {
    surveyResponseId: {
      required: true,
      type: String,
    },
    sectionId: {
      required: true,
      type: String,
    },
    isCurrentSection: {
      required: true,
      type: Boolean,
    },
    isLastSection: {
      required: true,
      type: Boolean,
    },
    sectionName: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const { sectionComplete } = SectionComplete(
      props.surveyResponseId,
      props.sectionId
    );

    return { isComplete: sectionComplete };
  },
};
</script>
