import SurveyTakerQuery from '@/graphql/SurveyTaker.query.gql';
import { useQuery, useResult } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

export default function surveyTaker(hmisid) {
  const { result, onError, onResult, loading } = useQuery(
    SurveyTakerQuery,
    {
      hmisid,
    },
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const surveyTaker = useResult(result, null, (data) => data.SurveyTaker);

  onError((err) => {
    logErrorMessages(err);
  });

  return {
    surveyTakerQuery: result,
    surveyTaker,
    loading,
    onResult,
  };
}
