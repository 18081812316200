import GetAnswersQuery from '@/graphql/GetAnswers.query.gql';
import { useQuery, useResult } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

export default function useGetAnswersQuery(
  surveyResponse,
  question,
  first = 1
) {
  const { result, onError, loading } = useQuery(
    GetAnswersQuery,
    {
      surveyResponse,
      question,
      first,
    },
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const dataPoints = useResult(result, null, (data) => data.DataPoint);
  const answers = useResult(
    result,
    null,
    (data) => data.DataPoint?.[0]?.answers
  );
  const answerIds = useResult(result, null, (data) =>
    data.DataPoint?.flatMap((dp) =>
      dp?.answers?.map((answer) => answer?.id)
    ).filter((el) => el)
  );
  const firstAnswerId = useResult(
    result,
    null,
    (data) => data.DataPoint?.[0]?.answers?.filter((obj) => obj.id)[0].id
  );

  onError((err) => {
    logErrorMessages(err);
  });

  return {
    dataPoints,
    answers,
    answerIds,
    firstAnswerId,
    loading,
  };
}
