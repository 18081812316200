import { createRouter, createWebHistory } from 'vue-router';
import SurveyResponse from '@/views/SurveyResponse';
import Temp from '@/views/Temp';
import Callback from '@/views/Callback';
import ErrorPage from '@/views/Error';
import BasicInformation from '@/components/BasicInformation';
import SurveyTaker from '@/components/SurveyTaker';
//import { routeGuard } from '@/auth';

const routes = [
  {
    path: '/survey/:surveyResponse',
    name: 'SurveyResponse',
    component: SurveyResponse,
    props: true,
    /* disabling this routeGuard for now, since we'll always have the auth0 popup show up when unauthenticated */
    //beforeEnter: routeGuard,
  },
  {
    path: '/surveyTaker/:hmisid',
    name: 'BasicInformation',
    component: BasicInformation,
    props: (route) => {
      const query = {};
      // passing mode query param as prop to BasicInformation
      if (route.query?.mode) {
        query.mode = route.query.mode;
      }
      return Object.assign({}, query, route.params);
    },
  },
  {
    path: '/surveyTaker',
    name: 'SurveyTaker',
    component: SurveyTaker,
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback,
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage,
  },
  {
    path: '/temp',
    name: 'temp',
    component: Temp,
  },
  {
    path: '/',
    name: 'Home',
    component: SurveyTaker,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
