import RenderQuestionQuery from '@/graphql/RenderQuestion.query.gql';
import { useQuery, useResult } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

export default function useRenderQuestionQuery(question, surveyResponse) {
  const { result, onError } = useQuery(
    RenderQuestionQuery,
    {
      question,
      surveyResponse,
    },
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const renderQuestion = useResult(result, null, (data) => data.RenderQuestion);

  onError((err) => {
    logErrorMessages(err);
  });

  return {
    renderQuestion,
  };
}
