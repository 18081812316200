<template>
  <textarea v-model="change" />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  name: 'QuestionTextarea',
  props: {
    prompt: Object,
    questionKey: {
      type: String,
      required: true,
    },
    answer: String,
  },
  computed: {
    ...mapState(['questions']),
    ...mapGetters(['answerByQuestionKey']),
    change: {
      get() {
        return this.answerByQuestionKey(this.questionKey);
      },
      set(answer) {
        return this.answerQuestion({
          questionKey: this.questionKey,
          answer,
        });
      },
    },
  },
  methods: {
    ...mapActions(['answerQuestion']),
  },
};
</script>
