import { createApp, h } from 'vue';
import markdown from 'vue3-markdown-it';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/styles/tailwind.css';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { DefaultApolloClient } from '@vue/apollo-composable';
import SmoothScroll from 'vue3-smooth-scroll';
import { setupAuth } from './auth';

const authConfig = {
  domain: process.env.VUE_APP_AUTH0_DOMAIN || 'citizentric.us.auth0.com',
  client_id:
    process.env.VUE_APP_AUTH0_CLIENTID || 'ieTaJbJ3xIYIqHbbUZS2EIWQgDw2Ww5u',
  redirect_uri:
    process.env.VUE_APP_AUTH0_REDIRECTURI || 'http://localhost:8080/callback',
  audience:
    process.env.VUE_APP_AUTH0_AUDIENCE || 'https://api-dev.aroyomatch.com',
};

function callbackRedirect(appState) {
  router.push(appState?.targetUrl || '/');
}

setupAuth(authConfig, callbackRedirect).then((auth) => {
  const app = createApp({
    render() {
      return h(App);
    },
  })
    .use(store)
    .use(router)
    .use(auth)
    .use(markdown)
    .use(SmoothScroll);

  async function getToken() {
    try {
      return await app._context.config.globalProperties.$auth.getTokenSilently();
    } catch (_) {
      return await app._context.config.globalProperties.$auth.getTokenWithPopup();
    }
  }

  const defaultClient = async () => {
    const bearer = 'Bearer ' + (await getToken());

    return new ApolloClient({
      uri: process.env.VUE_APP_API_URL || 'http://localhost:4001/graphql',
      cache: new InMemoryCache(),
      headers: {
        Authorization: bearer,
      },
    });
  };

  (async () =>
    app.provide(DefaultApolloClient, await defaultClient()).mount('#app'))();
});
