import CreateParticipantFromSurveyResponseMutation from '@/graphql/CreateParticipantFromSurveyResponse.mutation.gql';
import { useMutation } from '@vue/apollo-composable';

export default function useCreateParticipantFromSurveyResponseMutation() {
  const { mutate: createParticipant, onError, loading } = useMutation(
    CreateParticipantFromSurveyResponseMutation,
    () => ({
      fetchPolicy: 'no-cache',
      refetchQueries: () => ['SurveyTaker'],
    })
  );

  return {
    createParticipant,
    loading,
    onError,
  };
}
