<template>
  <div :class="size ? `h-${size} w-${size}` : 'h-12 w-12'">
    <div
      :class="size ? `avatar h-${size} w-${size}` : 'avatar h-12 w-12'"
      :style="{ backgroundColor: colorizer(name) }"
    >
      <span :class="avatarFontSize ? `text-${avatarFontSize}` : `text-base`">{{
        makeAvatarText(name)
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  methods: {
    makeAvatarText: (name) =>
      name
        .split(' ')
        .map((word) => word?.charAt(0))
        .filter((v) => v)
        .slice(0, 3)
        .join(''),
    colorizer: (name) => {
      let hash;
      for (let i = 0; i < name.length; i++)
        hash = (Math.imul(31, hash) + name.charCodeAt(i)) | 0;
      var c = (hash & 0x00ffffff).toString(16).toUpperCase();
      const color = 'FFFFFF'.substring(0, 6 - c.length) + c;
      return '#' + color;
    },
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
      validator: function (str) {
        return Number.isInteger(parseInt(str));
      },
    },
    avatarFontSize: {
      type: String,
      required: false,
    },
    mode: {
      type: String,
      default: 'CREATE',
      validator: function (str) {
        switch (str) {
          case 'VIEW':
          case 'CREATE':
          case 'UPDATE':
            return true;
        }
        return false;
      },
    },
  },
};
</script>
