<template>
  <section class="relative" :class="isLanguageModalOpen ? 'z-50' : ''">
    <img
      :src="worldIconUrl"
      @click.self="toggleLanguageSelector"
      class="cursor-pointer"
    />
    <div
      v-show="isLanguageModalOpen"
      class="modal-backdrop-base modal-backdrop-ls"
      @click.self="toggleLanguageSelector"
    >
      <div class="modal-wrapper-base modal-wrapper-ls">
        <div class="modal-header-base modal-header-ls">
          <div
            @click.self="toggleLanguageSelector"
            class="justify-self-end cursor-pointer text-3xl"
          >
            &times;
          </div>
          <div>Select Language</div>
        </div>
        <div class="modal-body-base modal-body-ls">
          <div
            v-for="l in languages"
            :value="l.id"
            :key="l.id"
            class="language-option"
          >
            <label :for="l.id" class="pl-3.5 cursor-pointer">
              {{ l.label }}
            </label>
            <input
              type="radio"
              :id="l.id"
              :value="l.id"
              name="languageSelector"
              :answer="language === l.id"
              v-model="languageSelect"
            />
          </div>
        </div>
        <div class="modal-footer-base modal-footer-ls"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'LanguageSelector',
  computed: {
    ...mapState(['language', 'profileOpen', 'isModalOpen']),
    languageSelect: {
      get() {
        return this.language;
      },
      set(value) {
        this.setLanguage(value);
      },
    },
  },
  methods: {
    ...mapActions(['setLanguage', 'setModalOpen']),
    toggleLanguageSelector() {
      if (!this.profileOpen && !this.isModalOpen) {
        this.setModalOpen(true);
        this.isLanguageModalOpen = !this.isLanguageModalOpen;
      } else if (this.isLanguageModalOpen && this.isModalOpen) {
        this.setModalOpen(false);
        this.isLanguageModalOpen = !this.isLanguageModalOpen;
      }
    },
  },
  data() {
    return {
      languages: [
        {
          id: 'en',
          label: 'English',
        },
        {
          id: 'es',
          label: 'Español',
        },
        /*
        {
          id: 'de',
          label: 'Deutsch',
        },
        */
      ],
      worldIconUrl: require('../assets/styles/icons/world-icon.svg'),
      isLanguageModalOpen: false,
    };
  },
};
</script>
