import CreateSurveyTakerMutation from '@/graphql/CreateSurveyTaker.mutation.gql';
import { useMutation } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

export default function useCreateSurveyTakerMutation() {
  const { mutate: createSurveyTaker, onError, loading } = useMutation(
    CreateSurveyTakerMutation,
    () => ({
      fetchPolicy: 'no-cache',
      refetchQueries: () => ['SurveyTaker'],
    })
  );

  onError((err) => {
    logErrorMessages(err);
  });

  return {
    createSurveyTaker,
    loading,
  };
}
