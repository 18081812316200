<template>
  <section class="relative" :class="profileOpen ? 'z-50' : ''">
    <div class="grid gap-x-px grid-flow-col justify-items-center items-center">
      <div class="w-8 h-8">
        <img
          :src="parProfile"
          class="cursor-pointer relative col-start-1 col-end-2"
          @click="toggleParProfile"
        />
      </div>
      <div class="xs:hidden md:block">{{ getUserName(email) }}</div>
      <div
        class="w-5 h-5 justify-self-start transform rotate-90 xs:hidden md:block"
      >
        <img
          :src="triangle"
          class="cursor-pointer relative col-start-1 col-end-2"
          @click="toggleParProfile"
        />
      </div>
    </div>
    <div
      v-show="profileOpen"
      class="modal-backdrop-base modal-backdrop-profile"
      @click.self="toggleParProfile"
    >
      <div class="modal-wrapper-base modal-wrapper-profile mr-2">
        <div class="modal-header-profile text-left mb-2 md:hidden">
          <h2>Profile</h2>
        </div>
        <div class="modal-body-base modal-body-profile">
          <div class="border-t border-gray-200 w-full pt-2 md:border-0">
            <button @click="logout">Sign out</button>
          </div>
        </div>
        <div class="modal-footer-base modal-footer-profile"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ParProfile',
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['profileOpen', 'isModalOpen']),
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    getUserName(email) {
      return email?.substring(0, email?.indexOf('@'));
    },
    toggleParProfile() {
      if (this.profileOpen && this.isModalOpen) {
        this.closeProfile();
        this.setModalOpen(false);
      } else if (!this.profileOpen && !this.isModalOpen) {
        this.openProfile();
        this.setModalOpen(true);
      }
    },
    ...mapActions(['openProfile', 'closeProfile', 'setModalOpen']),
  },
  data() {
    return {
      parProfile: require('../assets/styles/icons/user.svg'),
      triangle: require('../assets/styles/icons/triangle.svg'),
      isParProfileOpen: false,
    };
  },
};
</script>
