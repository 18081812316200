<template>
  <div class="modal-spinner">
    <ModalComponent :isModalOpen="isSpinnerOpen">
      <template #modalBody>
        <img :src="loadingSpinner" alt="Loading Spinner" />
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import ModalComponent from '@/components/ModalComponent.vue';

export default {
  name: 'LoadingSpinner',
  props: {
    isSpinnerOpen: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ModalComponent,
  },
  data() {
    return {
      loadingSpinner: require('../assets/styles/icons/loading.gif'),
    };
  },
};
</script>
