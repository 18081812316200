import FirstRequiredButUnansweredQuestionInSectionQuery from '@/graphql/FirstRequiredButUnansweredQuestionInSection.query.gql';
import { useQuery, useResult } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

export default function useFirstRequiredButUnansweredQuestionQuery(
  surveyResponse,
  section
) {
  const { result, onError } = useQuery(
    FirstRequiredButUnansweredQuestionInSectionQuery,
    {
      surveyResponse,
      section,
    },
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const firstRequiredButUnansweredQuestion = useResult(
    result,
    null,
    (data) => data.FirstRequiredButUnansweredQuestionInSection
  );

  onError((err) => {
    logErrorMessages(err);
  });

  return {
    firstRequiredButUnansweredQuestion,
  };
}
