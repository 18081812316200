import PARQuery from '@/graphql/PAR.query.gql';
import { useQuery, useResult } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

export default function surveyTaker(hmisid) {
  const { result, onError, loading } = useQuery(
    PARQuery,
    {
      hmisid,
    },
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const PAR = useResult(result, null, (data) => data.PAR);

  onError((err) => {
    logErrorMessages(err);
  });

  return {
    parQuery: result,
    PAR,
    loading,
  };
}
