<template>
  <div :class="menuOpen || isModalOpen ? 'menu-open' : ''">
    <HeaderComponent :class="hideHeaderBasedOnRoute ? 'hidden' : 'block'" />
    <router-view />
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent';
import { mapState } from 'vuex';
import router from '@/router';

export default {
  components: {
    HeaderComponent,
  },
  data() {
    return {
      routesToHideHeader: [
        'BasicInformation',
        'SurveyResponse',
        'SurveyTaker',
        'Home',
      ],
    };
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
  },
  computed: {
    hideHeaderBasedOnRoute() {
      return this.routesToHideHeader.includes(router.currentRoute.value.name);
    },
    ...mapState(['menuOpen', 'isModalOpen']),
  },
};
</script>
