<template>
  <div class="flex flex-col justify-center items-center mt-24 mb-5">
    <div class="text-2xl md:text-2rem font-bold">{{ sectionLabel }}</div>
    <div
      v-if="sectionDescription"
      class="bg-sectionDescriptionBg bg-opacity-40 mt-5 p-4"
    >
      <span class="text-lg">{{ sectionDescription }}</span>
    </div>
  </div>
  <Question
    v-for="question in surveyResponse?.survey?.SECTIONS_rel?.[
      currentSection
    ]?.Section.QUESTION_rel?.flatMap((qRel) => qRel.Question) || []"
    :key="question.id"
    :questionType="question.questionType"
    :required="question.required"
    :prompt="question['prompt_' + language]"
    :answerChoices="question.HAS_CHOICE_rel.flatMap((aRel) => aRel.Answer)"
    :questionKey="question.id"
    :min="question.min"
    :max="question.max"
    :surveyResponse="surveyResponse.id"
    :shouldCheck="shouldCheck"
    :id="question.id"
  />
  <div class="grid grid-cols-2 gap-x-3">
    <PreviousButton
      :hmisid="
        currentSection === 0 ? surveyResponse?.surveyTaker?.HMISID : null
      "
    />
    <SubmitFormButton
      v-if="currentSection === surveyResponse?.survey?.SECTIONS_rel.length - 1"
      :surveyResponse="surveyResponse.id"
      :executeOnClick="
        surveyResponseComplete ? undefined : toggleIncompleteModal
      "
    />
    <ContinueButton
      v-else
      :executeOnClick="sectionComplete ? nextSection : activateCheckAndScroll"
    />
    <SaveAndClose
      :disabled="loading"
      :buttonAction="isInEditMode ? recreateParAndClose : saveAndClose"
      :label="
        isInEditMode
          ? 'Save and return to dashboard'
          : 'Save and complete later'
      "
    />
    <div class="modal-incomplete">
      <ModalComponent
        :toggleModal="toggleIncompleteModal"
        :isModalOpen="isIncompleteModalOpen"
      >
        <template
          v-slot:modalHeader
          v-if="isInEditMode && !surveyResponseComplete"
        >
          <h2>Missing required question(s)</h2>
        </template>
        <template v-slot:modalHeader v-else>
          <h2>Confirm incomplete profile</h2>
        </template>
        <template
          v-slot:modalBody
          v-if="isInEditMode && !surveyResponseComplete"
        >
          <p>
            To submit, please use the navigation to return to all sections with
            required questions that need to be completed.
          </p>
        </template>
        <template v-slot:modalBody v-else>
          <p>
            Profiles that aren't complete are not eligible for shared housing
            matching. You can return to this profile later by entering the same
            HMIS ID
          </p>
        </template>
        <template
          v-slot:mdoalFooter
          v-if="isInEditMode && !surveyResponseComplete"
        >
          <button
            class="btn filled-btn md:w-20 justify-self-end"
            @click="toggleIncompleteModal"
          >
            Okay
          </button>
        </template>
        <template v-slot:mdoalFooter v-else>
          <button
            class="btn unfilled-btn md:w-20 justify-self-end"
            @click="() => saveAndClose()"
          >
            Cancel
          </button>
          <button
            class="btn filled-btn md:w-46"
            @click="() => saveAndClose(e, true)"
          >
            Complete profile later
          </button>
        </template>
      </ModalComponent>
    </div>
    <LoadingSpinner :isSpinnerOpen="showLoadingSpinner" />
  </div>
</template>

<script>
import { mapActions, mapState, useStore } from 'vuex';
import { toRefs, ref } from 'vue';
import { useRoute } from 'vue-router';
import Question from '@/components/Question';
import SubmitFormButton from '@/components/SubmitFormButton';
import ContinueButton from '@/components/ContinueButton';
import PreviousButton from '@/components/PreviousButton';
import SaveAndClose from '@/components/SaveAndClose';
import { goToParticipantDashboard } from '@/composables/useSaveAndFn';
import useCreateParticipantFromSurveyResponseMutation from '@/composables/useCreateParticipantFromSurveyResponseMutation';
import useSectionCompleteQuery from '@/composables/useSectionCompleteQuery';
import useFirstRequiredButUnansweredQuestionQuery from '@/composables/useFirstRequiredButUnansweredQuestionInSectionQuery';
import useSurveyResponseCompleteQuery from '@/composables/useSurveyResponseCompleteQuery';
import ModalComponent from '@/components/ModalComponent';
import LoadingSpinner from '@/components/LoadingSpinner';

export default {
  name: 'Section',
  components: {
    Question,
    SubmitFormButton,
    ContinueButton,
    PreviousButton,
    SaveAndClose,
    ModalComponent,
    LoadingSpinner,
  },
  props: {
    surveyResponse: {
      type: Object,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
    currentSection: {
      type: Number,
      required: true,
    },
  },
  computed: {
    sectionLabel() {
      return this.surveyResponse?.survey?.SECTIONS_rel?.[this.currentSection]
        ?.Section?.name_en;
    },
    sectionDescription() {
      return this.surveyResponse?.survey?.SECTIONS_rel?.[this.currentSection]
        ?.Section?.description_en;
    },
    ...mapState(['language', 'shouldCheck', 'showLoadingSpinner']),
  },
  methods: {
    ...mapActions(['nextSection', 'setShouldCheck', 'setShowLoadingSpinner']),
    async activateCheckAndScroll() {
      this.setShouldCheck(true);
      const myEl = document.getElementById(
        this.firstRequiredButUnansweredQuestion
      );

      this.$smoothScroll({
        scrollTo: myEl,
        updateHistory: false,
      });
    },
  },
  setup(props) {
    const store = useStore();
    const { section } = toRefs(props);
    const isIncompleteModalOpen = ref(false);
    const { sectionComplete } = useSectionCompleteQuery(
      props.surveyResponse.id,
      section
    );

    const {
      firstRequiredButUnansweredQuestion,
    } = useFirstRequiredButUnansweredQuestionQuery(
      props.surveyResponse.id,
      section
    );

    const { surveyResponseComplete } = useSurveyResponseCompleteQuery(
      props.surveyResponse.id
    );

    const toggleIncompleteModal = () => {
      isIncompleteModalOpen.value = !isIncompleteModalOpen.value;
    };

    const manageLoadingSpinner = (spinnerState) => {
      store.dispatch('setShowLoadingSpinner', spinnerState);
    };

    const saveAndClose = (e, redirect) => {
      manageLoadingSpinner(true);
      if (surveyResponseComplete.value || redirect) {
        manageLoadingSpinner(false);
        goToParticipantDashboard();
      } else {
        manageLoadingSpinner(false);
        toggleIncompleteModal();
      }
    };
    const {
      createParticipant,
      loading,
    } = useCreateParticipantFromSurveyResponseMutation();

    const recreateParAndClose = async () => {
      manageLoadingSpinner(true);
      if (surveyResponseComplete.value) {
        await createParticipant({ surveyResponse: props.surveyResponse.id });
        goToParticipantDashboard();
        manageLoadingSpinner(false);
      } else {
        manageLoadingSpinner(false);
        toggleIncompleteModal();
      }
    };

    const isInEditMode = useRoute().query.mode === 'UPDATE';

    return {
      saveAndClose,
      surveyResponseComplete,
      isIncompleteModalOpen,
      toggleIncompleteModal,
      firstRequiredButUnansweredQuestion,
      goToParticipantDashboard,
      isInEditMode,
      loading,
      recreateParAndClose,
      sectionComplete,
    };
  },
};
</script>
