<template>
  <div v-if="isModalOpen" class="modal-backdrop-base modal-backdrop">
    <div class="modal-wrapper-base modal-wrapper">
      <div class="modal-header-base modal-header">
        <div
          @click.self="toggleModal"
          class="justify-self-end cursor-pointer text-3xl"
        >
          &times;
        </div>
        <slot name="modalHeader"></slot>
      </div>
      <div class="modal-body-base modal-body">
        <slot name="modalBody"></slot>
      </div>
      <div class="modal-footer-base modal-footer">
        <slot name="mdoalFooter"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalComponent',
  props: {
    toggleModal: {
      type: Function,
    },
    isModalOpen: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
