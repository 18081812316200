<template>
  <div class="par-status text-darkText">
    <div class="flex flex-row items-center col-span-3 lg:col-span-1">
      <Avatar :name="name" :mode="mode" size="12" />
      <div class="text-left ml-4 w-max">
        <div class="font-bold text-lg">{{ name }}</div>
        <div class="text-sm">{{ hmisid }}</div>
      </div>
    </div>
    <div
      class="text-left text-sm col-span-3 mt-2 lg:mx-auto lg:col-span-1 lg:mt-0 lg:text-center"
      v-if="mode === 'VIEW' && status === 'Inactive'"
    >
      <span class="text-statusText">Status:</span> Inactive
    </div>
    <div
      class="text-left text-sm col-span-3 mt-2 lg:mx-auto lg:col-span-1 lg:mt-0 lg:text-center"
      v-else-if="mode === 'VIEW' && status === 'Active'"
    >
      <span class="text-statusText">Status:</span> Active
    </div>
    <div
      class="text-left text-sm col-span-3 mt-2 lg:mx-auto lg:col-span-1 lg:mt-0 lg:text-center"
      v-else
    >
      <span class="text-statusText">Status:</span> Incomplete Profile
    </div>
    <div
      v-if="mode === 'VIEW'"
      class="col-span-3 mt-8 lg:col-span-1 lg:mt-0 lg:text-right lg:text-lg"
    >
      <a
        :href="cancelUrl"
        class="btn unfilled-btn w-64 lg:w-36 mx-auto lg:mr-0"
      >
        View Profile
      </a>
    </div>
    <div
      class="col-span-3 mt-8 lg:col-span-1 lg:mt-0 lg:text-right lg:text-lg"
      v-else
    >
      <router-link
        :to="{
          name: 'BasicInformation',
          params: { hmisid },
          query: { mode },
        }"
      >
        <button
          v-if="mode === 'UPDATE'"
          class="filled-btn w-64 lg:w-36 mx-auto lg:mr-0"
        >
          Complete Profile
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar';
import { REACT_APP_BASE_URL } from '@/constants';

export default {
  name: 'SurveyTakerCard',
  components: {
    Avatar,
  },
  props: {
    hmisid: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
    status: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      default: 'CREATE',
      validator: function (str) {
        switch (str) {
          case 'VIEW':
          case 'CREATE':
          case 'UPDATE':
            return true;
        }
        return false;
      },
    },
  },
  setup(props) {
    const cancelUrl = `${REACT_APP_BASE_URL}/participant/detail/${props.id}`;
    return { cancelUrl };
  },
};
</script>
