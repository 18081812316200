<template>
  <HeaderComponent>
    <template v-slot:backButton>
      <router-link :to="{ name: 'SurveyTaker' }" class="text-primary text-3xl">
        <div class="flex flex-row justify-center items-center">
          &lt;
          <span class="text-primary text-smBase pl-1 font-bold">Back</span>
        </div>
      </router-link>
    </template>
    <template #parProfile>
      <ParProfile :email="loggedInUser?.email" />
    </template>
  </HeaderComponent>
  <h2 class="text-center font-bold text-2xl mt-8">Basic Information</h2>
  <section class="w-full p-2.5 mt-5 xs:max-w-md mx-auto">
    <form @submit.prevent="next" class="grid grid-cols-1">
      <label for="fullname" class="mt-8"
        >Full Name <span class="text-required font-bold">*Required</span></label
      >
      <input
        type="text"
        id="fullname"
        required
        v-model="name"
        class="inp h-14 lg:w-full"
      />
      <div class="flex flex-col mt-8">
        HMIS*
        <span
          class="hmis-inp-disabled flex items-center p-2 text-disabledText lg:w-full"
        >
          {{ hmisid }}
        </span>
      </div>
      <label for="dob" class="mt-8"
        >Date of Birth
        <span class="text-required font-bold">*Required</span></label
      >
      <input type="date" id="dob" v-model="dob" class="inp h-14 lg:w-full" />
      <label for="contactEmail" class="mt-8">Email</label>
      <input
        type="email"
        id="contactEmail"
        v-model="contactEmail"
        class="inp h-14 lg:w-full"
      />
      <label for="tel" class="mt-8">Telephone</label>
      <input type="tel" id="tel" v-model="phone" class="inp h-14 lg:w-full" />
      <div class="grid grid-cols-2 gap-x-3 items-baseline">
        <PreviousButton />
        <input
          type="submit"
          value="Continue"
          class="filled-btn m-0 mt-10 lg:w-full"
        />
      </div>
    </form>
    <SaveAndClose :buttonAction="saveAndClose" />
  </section>
</template>

<script>
import { ref } from 'vue';
import useSurveyTakerQuery from '@/composables/useSurveyTakerQuery';
import useCreateSurveyTakerMutation from '@/composables/useCreateSurveyTakerMutation';
import useMergeSurveyTakerMutation from '@/composables/useMergeSurveyTakerMutation';
import { goToParticipantDashboard } from '@/composables/useSaveAndFn';
import { SURVEY_ID } from '@/constants';
import HeaderComponent from '@/components/HeaderComponent';
import PreviousButton from '@/components/PreviousButton';
import SaveAndClose from '@/components/SaveAndClose';
import ParProfile from '@/components/ParProfile';
import router from '@/router';

export default {
  name: 'BasicInformation',
  beforeCreate: function () {
    // NOTE: understand/'fix' this when appropriate
    this.$options.components.HeaderComponent = require('../components/HeaderComponent').default;
  },
  components: {
    HeaderComponent,
    SaveAndClose,
    PreviousButton,
    ParProfile,
  },
  props: {
    hmisid: {
      type: String,
      required: true,
    },
    // this is not how I'd usually write components, but we have these weird flows that require components to know a little bit more about how they came to be
    mode: {
      type: String,
      default: 'CREATE',
      validator: function (str) {
        switch (str) {
          case 'CREATE':
          case 'UPDATE':
            return true;
        }
        return false;
      },
    },
  },
  setup(props) {
    let id = '';
    const name = ref('');
    const contactEmail = ref('');
    const dob = ref('');
    const phone = ref('');
    const loggedInUser = ref('');
    let mutate;
    if (props.mode === 'CREATE') {
      const { createSurveyTaker } = useCreateSurveyTakerMutation();
      mutate = createSurveyTaker;
    } else if (props.mode === 'UPDATE') {
      // fetch SurveyTaker and update name, contactEmail, phone refs, dob; set id
      const { onResult } = useSurveyTakerQuery(props.hmisid);
      onResult((queryResult) => {
        const qr = queryResult?.data?.SurveyTaker?.[0];
        name.value = qr.name;
        dob.value = qr.dob.formatted;
        contactEmail.value = qr.contactEmail;
        phone.value = qr.phone;
        // TODO: remove loggedInUser from this (see SH-754)
        loggedInUser.value = queryResult?.data?.me;
        id = qr.id;
      });
      const { mergeSurveyTaker } = useMergeSurveyTakerMutation();
      mutate = mergeSurveyTaker;
    }

    const executeMutation = async (redirectToParListView) => {
      const payload = {
        name: name.value,
        dob: dob.value,
        contactEmail: contactEmail.value,
        phone: phone.value,
        hmisid: props.hmisid,
        survey: SURVEY_ID,
        id,
      };
      // create or update SurveyTaker
      const result = await mutate(
        // only pass truthy values to the mutation
        Object.fromEntries(Object.entries(payload).filter((p) => p[1]))
      );
      if (result) {
        const surveyResponse =
          props.mode === 'CREATE'
            ? result?.data?.CreateSurveyTaker?.surveyResponses?.[0]?.id
            : result?.data?.MergeSurveyTaker?.surveyResponses?.[0]?.id;
        if (redirectToParListView) {
          // AND props.mode === 'UPDATE' => "Save & Close"
          return goToParticipantDashboard();
        } else {
          return router.push({
            name: 'SurveyResponse',
            params: {
              surveyResponse,
            },
          });
        }
      } else {
        // TODO: handle error case
      }
    };

    const skip = () => {
      if (props.mode === 'CREATE') return goToParticipantDashboard(); // "Cancel"
      executeMutation(true);
    };

    const next = () => {
      executeMutation();
    };

    const saveAndClose = () => {
      executeMutation(true);
    };

    const cancelText = props.mode === 'CREATE' ? 'Cancel' : 'Save & Close';

    return {
      loggedInUser,
      name,
      contactEmail,
      dob,
      phone,
      next,
      skip,
      cancelText,
      saveAndClose,
    };
  },
};
</script>
