import RequiredQuestionAnsweredQuery from '@/graphql/RequiredQuestionAnswered.query.gql';
import { useQuery, useResult } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

export default function useRequiredQuestionAnsweredQuery(
  question,
  surveyResponse
) {
  const { result, onError } = useQuery(
    RequiredQuestionAnsweredQuery,
    {
      question,
      surveyResponse,
    },
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const requiredQuestionAnswered = useResult(
    result,
    null,
    (data) => data.RequiredQuestionUnanswered
  );

  onError((err) => {
    logErrorMessages(err);
  });

  return {
    requiredQuestionAnswered,
  };
}
