<template>
  <div class="pt-6 pb-6 mx-auto max-w-full flex flex-col" v-if="renderQuestion">
    <div class="w-full text-text">
      <div class="flex pb-5 text-base lg:text-xl">
        <div class="w-full">
          <h3 class="font-medium text-left flex-row promptWrapper inline">
            <Markdown :source="prompt" />
            <span v-show="required" class="text-required inline">
              *Required
            </span>
          </h3>

          <h4 v-if="subHeader" class="subheader">
            <Markdown :source="subHeader" />
          </h4>
        </div>
      </div>
      <div
        v-show="shouldCheck && required && isUnanswered"
        class="text-required font-bold text-xl mb-2"
      >
        Please provide a response
      </div>
      <div
        class="relative"
        :class="[showHorizontalDisplay ? 'horizontalDisplay' : 'normal']"
      >
        <div
          v-show="shouldCheck && required && isUnanswered"
          class="-ml-px absolute mt-0.5 top-0 -left-4 w-1 h-full bg-required"
          aria-hidden="true"
        />
        <QuestionChoice
          v-if="
            questionType === 'SINGLE_CHOICE' ||
            questionType === 'MULTIPLE_CHOICE'
          "
          :answerChoices="answerChoices"
          :questionKey="questionKey"
          :prompt="prompt"
          :surveyResponse="surveyResponse"
          :questionType="questionType"
        />
        <QuestionInput
          v-if="
            questionType === 'INPUT_NUMBER' || questionType === 'INPUT_TEXT'
          "
          :questionKey="questionKey"
          :prompt="prompt"
          :required="required"
          :min="min"
          :max="max"
          :surveyResponse="surveyResponse"
          :questionType="questionType"
        />
        <QuestionTextarea
          v-if="questionType === 'FREEFORM'"
          :questionKey="questionKey"
          :prompt="prompt"
          :required="required"
          :surveyResponse="surveyResponse"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Markdown from 'vue3-markdown-it';
import QuestionChoice from '@/components/QuestionChoice.vue';
import QuestionInput from '@/components/QuestionInput.vue';
import QuestionTextarea from '@/components/QuestionTextarea.vue';
import useRenderQuestionQuery from '@/composables/useRenderQuestionQuery';
import useRequiredQuestionAnsweredQuery from '@/composables/useRequiredQuestionUnanswered';

export default {
  name: 'Question',
  props: {
    questionType: String,
    required: Boolean,
    prompt: String,
    answerChoices: Array,
    questionKey: String,
    min: Number,
    max: Number,
    surveyResponse: String,
    shouldCheck: Boolean,
  },
  components: {
    Markdown,
    QuestionChoice,
    QuestionInput,
    QuestionTextarea,
  },
  computed: {
    subHeader() {
      switch (this.questionType) {
        case 'MULTIPLE_CHOICE':
          return '(check all that apply)';
      }
      return '';
    },
    showHorizontalDisplay() {
      return (
        this.questionType === 'MULTIPLE_CHOICE' &&
        this.answerChoices.every((answerChoice) => !isNaN(answerChoice.text_en))
      );
    },
  },
  setup(props) {
    const { renderQuestion } = useRenderQuestionQuery(
      props.questionKey,
      props.surveyResponse
    );

    const { requiredQuestionAnswered } = useRequiredQuestionAnsweredQuery(
      props.questionKey,
      props.surveyResponse
    );

    return {
      renderQuestion,
      isUnanswered: requiredQuestionAnswered,
    };
  },
};
</script>
