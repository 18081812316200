<template>
  <nav
    class="w-full fixed top-0 left-0 bg-modalBackdropBg bg-opacity-70 md:static md:w-max md:block md:min-h-screen z-50"
    @click.self="setNavAndModalStates"
  >
    <aside
      class="bg-navBg text-white w-11/12 h-screen overflow-y-auto md:w-max md:overflow-visible md:h-full"
    >
      <div class="grid grid-rows-2 px-5 pt-12 pb-9">
        <div
          @click="setNavAndModalStates"
          class="justify-self-end cursor-pointer md:hidden text-3xl"
        >
          &times;
        </div>
        <h2 class="text-xl">Add Participant</h2>
      </div>
      <ol class="overflow-hidden pr-5 pl-5">
        <li class="pb-7 relative">
          <div
            class="-ml-px absolute mt-0.5 top-4 left-1 w-0.5 h-full bg-white"
            aria-hidden="true"
          />
          <span
            class="relative flex items-center group"
            aria-current="section"
            @click="gotToBasicInformation"
          >
            <span class="h-9 flex items-center">
              <span
                class="relative z-10 w-2 h-2 flex items-center justify-center bg-white rounded-full transform scale-150"
              >
                <CheckIcon class="w-5 h-5 text-navBg" aria-hidden="true" />
              </span>
            </span>
            <span class="ml-4 min-w-0 flex flex-col text-white">
              <button class="tracking-wide whitespace-nowrap">
                Basic Information
              </button>
            </span>
          </span>
        </li>
        <li
          v-for="(section, sidx) in sections"
          :key="'nav-' + sidx"
          :class="[sidx !== sections.length - 1 ? 'pb-7' : '', 'relative']"
          @click="setSectionAndCloseNav(sidx)"
        >
          <NavigationListItem
            :surveyResponseId="surveyResponseId"
            :sectionId="section.id"
            :isCurrentSection="sidx === currentSection"
            :isLastSection="sidx === sections.length - 1"
            :sectionName="section['name_' + language]"
          />
        </li>
      </ol>
    </aside>
  </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { useRoute } from 'vue-router';
import { CheckIcon } from '@heroicons/vue/solid';
import NavigationListItem from '@/components/NavigationListItem';
export default {
  name: 'Navigation',
  components: {
    NavigationListItem,
    CheckIcon,
  },
  props: {
    closeNavigation: {
      type: Function,
      required: true,
    },
    hmisid: {
      type: String,
    },
    sections: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(['currentSection', 'language']),
  },
  methods: {
    ...mapActions(['setCurrentSection', 'setModalOpen']),
    // TODO: refactor
    gotToBasicInformation() {
      this.$router.push(`/surveyTaker/${this?.$props?.hmisid}?mode=UPDATE`);
    },
    setNavAndModalStates() {
      this.closeNavigation();
      this.setModalOpen(false);
    },
    setSectionAndCloseNav(sectionNumber) {
      this.setCurrentSection(sectionNumber);
      this.setNavAndModalStates();
    },
  },
  setup() {
    const route = useRoute();
    const surveyResponseId = route?.params?.surveyResponse;

    return {
      surveyResponseId,
    };
  },
};
</script>
